import React, { Component } from "react";
import Helmet from "react-helmet";

const siteUrl = "https://thedutchonlineacademy.com";
const logoStaticUrl = "https://thedutchonlineacademy.com/loco_circled.png";
const siteName = "The Dutch Online Academy";
const contactPhone = "+34619488441";
const contactMail = "hello@thedutchonlineacademy.com";

export class ProductSchema extends Component {
  render() {
    const {
      name,
      image,
      description = "",
      price,
      path,
      sku = "01",
    } = this.props;
    const markup = {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: name,
      image: [image],
      description: description,
      sku: "00000000" + sku,
      mpn: "00000" + sku,
      brand: {
        "@type": "Brand",
        name: "The Dutch Online Academy",
      },
      url: path,
      offers: {
        "@type": "Offer",
        url: path,
        priceCurrency: "EUR",
        price: price,
        priceValidUntil: "2021-11-20",
        itemCondition: "https://schema.org/NewCondition",
        availability: "https://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: "The Dutch Online Academy",
        },
      },
    };
    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(markup)}</script>
      </Helmet>
    );
  }
}

export class BreadCrumbSchema extends React.Component {
  parseItemToFullSchema(item, index) {
    if (item.item === "") {
      return JSON.stringify({
        "@type": "ListItem",
        position: index + 1,
        name: item.name,
      });
    }
    return JSON.stringify({
      "@type": "ListItem",
      position: index + 1,
      name: item.name,
      item: siteUrl + item.item,
    });
  }
  render() {
    const markup =
      `
			{
				"@context": "https://schema.org",
				"@type": "BreadcrumbList",
				"itemListElement": [` +
      this.props.schema.map((item, index) =>
        this.parseItemToFullSchema(item, index)
      ) +
      `]
		}
		`;

    return (
      <Helmet>
        <script type="application/ld+json">{markup}</script>
      </Helmet>
    );
  }
}

export class OrganizationSchema extends React.Component {
  render() {
    const markup = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: siteUrl,
      logo: logoStaticUrl,
      name: siteName,
      email: contactMail,
      contactPoint: {
        "@type": "ContactPoint",
        telephone: contactPhone,
        contactType: "Customer service",
      },
    };
    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(markup)}</script>
      </Helmet>
    );
  }
}

export class BlogPostSchema extends React.Component {
  render() {
    const {
      updatedAt,
      createdAt,
      path,
      title,
      imageUrl,
      description = "",
    } = this.props;
    const markup = {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": path,
      },
      headline: title,
      image: {
        "@type": "ImageObject",
        url: imageUrl,
      },
      datePublished: createdAt,
      dateModified: updatedAt,
      author: {
        "@type": "Person",
        name: "Ida Mathilde",
      },
      publisher: {
        "@type": "Organization",
        name: "The Dutch Online Academy",
        logo: {
          "@type": "ImageObject",
          url: logoStaticUrl,
        },
      },
      description: description,
    };

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(markup)}</script>
      </Helmet>
    );
  }
}
