import { theme } from "../../theme/variables";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const ArticleIntro = styled.div`
  background-color: ${(props) => props.theme.primary};
  box-shadow: 3px 3px 6px ${(props) => props.theme.default_BlackTransparent};
  padding-bottom: 200px;
  h1 {
    color: white;
    margin-bottom: 1em;
  }
  p {
    color: white;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    a {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
    &:last-child {
      margin-bottom: 3rem;
    }
  }
  ${MEDIA.PHONE`
		padding-bottom: 60px;

		p{
			margin-bottom: 0.5rem;
			&:last-child{
				margin-bottom: 1rem;
			}
		}
		h1{

			font-size: 1.8rem;
		}
	`}
`;

export const ArticleIntroContainer = styled.div`
  max-width: ${theme.contentMaxWidth};
  margin: auto;
  margin-top: -2px;
  padding: 52px 32px 20px;

  ${MEDIA.PHONE`
		padding: 68px 16px 16px;
	`}
  margin-bottom: 2rem;
`;

export const ArticleImageContainer = styled.div`
  max-width: ${theme.contentWideMaxWidth};
  padding: 0px 16px 32px;
  margin: auto;
  margin-top: -190px;
  .gatsby-image-wrapper {
    background-color: ${(props) => props.theme.primary};
    box-shadow: -5px -5px 10px #ffffff66, 5px 5px 15px #00000055;
    border-radius: 10px;
  }
  ${MEDIA.PHONE`
		margin-top: -70px;

	`}
`;
