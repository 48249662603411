import React from "react";

import styled from "styled-components";
import { theme } from "../../theme/variables";
import Link from "gatsby-link";
import MEDIA from "../../theme/helpers/mediaTemplates";
import RelatedItemsConfig from "../../../data/RelatedItemsConfig";
import pathBuilder from "../../utils/pathBuilder";

const LineItem = styled.li`
  list-style: none;
  padding: 0px;
  margin: 0px;
  a {
    margin-bottom: 39px;
    padding: 32px;
    border-radius: 20px;
    display: block;
    font-family: ${theme.fontHeaders};
    font-weight: 700;
    color: white;
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.lighterOne},
      ${(props) => props.theme.primary}
    );
    box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
      -4px -4px 4px ${(props) => props.theme.lighterTwo};
  }
`;

const RelatedContentContainer = styled.div`
  /* background-color:${theme.colorSystemLight}; */
  /* margin: 60px 32px 0px;
    ${MEDIA.PHONE`
        margin: 40px 16px 0px;

        `} */
  .content {
    padding: 32px;
    border-radius: 32px;
    margin-bottom: 32px;
    background: linear-gradient(
      135deg,
      ${(props) => props.theme.default_lighterTwo},
      ${(props) => props.theme.default_lighterOne}
    );
    box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
      -2px -2px 4px ${(props) => props.theme.default_lighterOne};
    ul {
      padding: 0px;
      margin: 0px;
    }
  }
`;

const RelatedLine = (props) => {
  const { slug, __typename, node_locale, title } = props.node;
  return (
    <LineItem>
      <Link to={pathBuilder(node_locale, __typename, slug)}>{title}</Link>
    </LineItem>
  );
};

const RelatedContent = (props) => {
  const { relatedArticles, relatedBooks, relatedPills, lang, id } = props;
  const { relatedBooksTitle, relatedArticlesTitle, relatedGrammarTitle } =
    RelatedItemsConfig[lang];
  return (
    <RelatedContentContainer id={id}>
      <div className="content">
        {relatedBooks ? (
          <div>
            <h2>{relatedBooksTitle}</h2>
            <ul>
              {relatedBooks.map((edge) => (
                <RelatedLine
                  key={edge.slug}
                  node={edge}
                  type="books"
                  styles="shopColorBg"
                />
              ))}
            </ul>
          </div>
        ) : null}
        {relatedPills ? (
          <div>
            <h2>{relatedGrammarTitle}</h2>

            <ul>
              {relatedPills.map((edge) => (
                <RelatedLine
                  key={edge.slug}
                  node={edge}
                  type="grammar"
                  styles="grammarColorBg"
                />
              ))}
            </ul>
          </div>
        ) : null}
        {relatedArticles ? (
          <div>
            <h2>{relatedArticlesTitle}</h2>

            <ul>
              {relatedArticles.map((edge) => (
                <RelatedLine
                  key={edge.slug}
                  node={edge}
                  type="articles"
                  styles="blogColorBg"
                />
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </RelatedContentContainer>
  );
};

export default RelatedContent;
