import React from "react";
import * as Styled from "./Breadcrumbs.styled";

import Link from "gatsby-link";
import pathBuilder from "../../utils/pathBuilder";
import { BreadCrumbSchema } from "../Seo/Schemas";

const Breadcrumbs = (props) => {
  const { type, title, lang, meta } = props;

  const homeLink = pathBuilder(lang, "ContentfulPage", "", "index");
  const homeTitle = "TDOA";

  let categoryLink;
  let categoryTitle;

  if (type === "ContentfulGrammarPill") {
    categoryLink = pathBuilder(lang, "contentfulPage", "", "grammar");
    categoryTitle = "Dutch Grammar";
  }
  if (type === "ContentfulArticle") {
    categoryLink = pathBuilder(lang, "contentfulPage", "", "articles");
    categoryTitle = "Dutch Blog";
  }
  if (type === "ContentfulBook") {
    categoryLink = pathBuilder(lang, "contentfulPage", "", "shop");
    categoryTitle = "Dutch with PDFs";
  }

  const schema = [
    {
      name: homeTitle,
      item: homeLink,
    },
    {
      name: categoryTitle,
      item: categoryLink,
    },
    {
      name: title,
      item: "",
    },
  ];
  return (
    <Styled.BreadcrumbsContainer id="breadcrumbs">
      <BreadCrumbSchema schema={schema} />
      <Styled.BreadcrubmsContent>
        {meta ? (
          <span>
            <span style={{ fontWeight: 700, marginRight: "20px" }}>{meta}</span>
          </span>
        ) : null}

        <span>
          <Link to={homeLink}>{homeTitle}</Link>
          {"> "}
          <Link to={categoryLink}>{categoryTitle}</Link>
          {"> "}
          <span className="span">{title}</span>
        </span>
      </Styled.BreadcrubmsContent>
    </Styled.BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
