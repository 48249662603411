const RelatedItemsConfig = {
  en: {
    relatedBooksTitle: "Some exercise PDFs you might like",
    relatedArticlesTitle: "Other posts",
    relatedGrammarTitle: "Related grammar lessons",
  },
  nl: {
    relatedBooksTitle: "Interessante PDFs met oefeningen",
    relatedArticlesTitle: "Andere posts",
    relatedGrammarTitle: "Gerelateerde grammatica",
  },
};

module.exports = RelatedItemsConfig;
