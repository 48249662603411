import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const BreadcrumbsContainer = styled.div`
  text-align: left;
  padding: 4px 10px;
  box-shadow: inset 1px 2px 1px ${(props) => props.theme.darkerOne},
    inset -1px -2px 2px ${(props) => props.theme.lighterOne};
  background-color: ${(props) => props.theme.primary};
  border-radius: 30px;
  height: auto;
`;
export const BreadcrubmsContent = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${MEDIA.MIN_DESKTOP`
      overflow-x: auto;
  `}
  ::-webkit-scrollbar {
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.primary};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.halfUp};
  }
  span {
    white-space: nowrap;
  }
  span,
  a {
    font-family: ${theme.fontHeaders};
    color: ${(props) => props.theme.darkerTwo};
    font-size: 0.6rem;
  }
  a,
  .span {
    font-weight: 700;
  }
`;
